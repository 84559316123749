import Swiper from "swiper";
import MatchHeight from "matchheight";
import "lightgallery.js";

function drawerMenu() {
  const button = document.getElementById("sp_menu_btn");
  const menu = document.getElementById("main_menu");
  button.onclick = function() {
    menu.classList.toggle("open");
  };
}

function serch_sidebar() {
  const button = document.getElementById("serch_btn");
  const menu = document.getElementById("serch_sidebar");
  button.onclick = function() {
    menu.classList.toggle("active");
  };
}

function serch_sidebar_close1() {
  const button = document.getElementById("serch_sidebar_bg");
  const menu = document.getElementById("serch_sidebar");
  button.onclick = function() {
    menu.classList.toggle("active");
  };
}

function serch_sidebar_close2() {
  const button = document.getElementById("close_btn");
  const menu = document.getElementById("serch_sidebar");
  button.onclick = function() {
    menu.classList.toggle("active");
  };
}

jQuery('a[href^="#"]').click(function() {
  var href = jQuery(this).attr("href");
  jQuery("html,body").animate({ scrollTop: jQuery(href).offset().top }, "500");
  return false;
});

new Swiper(".swiper-container", {
  effect: "fade",
  loop: true,
  speed: 3000,
  autoplay: {
    delay: 4000,
    disableOnInteraction: false
  }
});

jQuery(".q_column").click(function() {
  jQuery(this).toggleClass("active");
});

lightGallery(document.getElementById("item-photo-clm"), {
  thumbnail: true
});

drawerMenu();
serch_sidebar();
serch_sidebar_close1();
serch_sidebar_close2();
MatchHeight.init();
